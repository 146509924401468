@import '@almbrand/styles/dist/media-mixins.scss';

.p-spacing-xs {
	padding: var(--spacing--md);
}

.p-spacing-sm {
	padding: var(--spacing--lg);
}

.p-spacing-md {
	padding: var(--spacing--xl);
}

.p-spacing-lg {
	padding: var(--spacing--3xl);
}

.p-spacing-xl {
	padding: var(--spacing--5xl);
}

.p-spacing-md-r {
	// Medium-Responsive
	padding: var(--spacing--1xl);

	@include mq('sm') {
		padding: var(--spacing--4xl);
	}

	@include mq('lg') {
		padding: var(--spacing--7xl);
	}
}

.p-spacing-r {
	padding: var(--spacing--4xl);

	@include mq('sm') {
		padding: var(--spacing--7xl);
	}

	@include mq('lg') {
		padding: var(--spacing--9xl);
	}
}

.pt-spacing-xs {
	// X-Small
	padding-top: var(--spacing--md);
}

.pt-spacing-sm {
	// Small
	padding-top: var(--spacing--lg);
}

.pt-spacing-md {
	// Medium
	padding-top: var(--spacing--xl);
}

.pt-spacing-lg {
	// Large
	padding-top: var(--spacing--3xl);
}

.pt-spacing-xl {
	// X-Large
	padding-top: var(--spacing--5xl);
}

.pt-spacing-md-r {
	// Medium-Responsive
	padding-top: var(--spacing--1xl);

	@include mq('sm') {
		padding-top: var(--spacing--4xl);
	}

	@include mq('lg') {
		padding-top: var(--spacing--7xl);
	}
}

.pt-spacing-r {
	// Default-Responsive
	padding-top: var(--spacing--4xl);

	@include mq('sm') {
		padding-top: var(--spacing--7xl);
	}

	@include mq('lg') {
		padding-top: var(--spacing--9xl);
	}
}

.pb-spacing-xs {
	padding-bottom: var(--spacing--md);
}

.pb-spacing-sm {
	padding-bottom: var(--spacing--lg);
}

.pb-spacing-md {
	padding-bottom: var(--spacing--xl);
}

.pb-spacing-lg {
	padding-bottom: var(--spacing--3xl);
}

.pb-spacing-xl {
	padding-bottom: var(--spacing--5xl);
}

.pb-spacing-md-r {
	// Medium-Responsive
	padding-bottom: var(--spacing--1xl);

	@include mq('sm') {
		padding-bottom: var(--spacing--4xl);
	}

	@include mq('lg') {
		padding-bottom: var(--spacing--7xl);
	}
}

.pb-spacing-r {
	padding-bottom: var(--spacing--4xl);

	@include mq('sm') {
		padding-bottom: var(--spacing--7xl);
	}

	@include mq('lg') {
		padding-bottom: var(--spacing--9xl);
	}
}
