.Header {
	background-color: var(--color-primary-4);
	transition: all 0.3s ease;
	position: sticky;
	z-index: 100;
	top: 0;
}

.Header_hidden {
	transform: translateY(-100%);
}

.Header_sticky {
	transform: translateY(0%); /* Slide in header */
}
