@import '@almbrand/styles/dist/media-mixins.scss';

.Form {
	&__Title {
		// main title
		text-align: center;
	}

	&__Description {
		// description
		text-align: center;
	}

	&__Status {
		// Status message?? Don't know what it is yet
	}

	&__MainBody {
		// main body, all form elements like inputs, checkboxes etc.
	}

	&__Element {
		&__Caption {
			// Form label
			margin-bottom: var(--spacing--md);
		}

		textarea {
			resize: vertical;
			height: auto;
		}
	}

	input,
	select,
	textarea {
		color: var(--color-primary-6);
	}
}

.FormTextbox {
	position: relative;
	margin-bottom: var(--spacing--lg);

	&:focus-within .Form__Element__Caption,
	&:has(.FormTextbox__Input:not(:placeholder-shown)) .Form__Element__Caption {
		transform: translateY(-0.29rem);
		font-size: 0.69rem;
		line-height: 0.8rem;
		opacity: 0.6;
	}

	&:focus-within .FormTextbox__Input,
	&:has(.FormTextbox__Input:not(:placeholder-shown)) .FormTextbox__Input {
		padding: var(--spacing--lg) var(--spacing--lg) var(--spacing--xs);
		line-height: 1.5rem;
	}

	&:focus-within .FormTextbox__Input,
	&:has(.FormTextbox__Input:valid:not(:placeholder-shown)) .FormTextbox__Input {
		border: 1px solid var(--color-primary-5);
	}

	// Label
	.Form__Element__Caption {
		position: absolute;
		top: 1.29rem;
		left: var(--spacing--lg);
		pointer-events: none;
		transition: all 150ms ease;
		z-index: 1;
		line-height: 1.5rem;
		font-size: var(--font-size--xs);
		font-weight: var(--font-weight--regular);
		color: var(--color-secondary-2);

		&:after {
			content: '' !important;
		}
	}

	//Input
	&__Input {
		&:hover,
		&:focus {
			transition: all 100ms ease-in-out;
			border: 1px solid var(--color-primary-5);
		}
	}

	textarea {
		height: 220px;
	}
}

.FormTextbox__Input,
.FormDateTime__Input,
.FormSelection select {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 64px;
	margin: 0;
	padding: var(--spacing--md) var(--spacing--lg);
	border: 1px solid var(--color-secondary-4);
	border-radius: var(--corner-size--base);
	outline: none;
	background-color: var(--color-primary-4);
	font-size: var(--font-size--xs);
	color: var(--color-primary-6);
	line-height: 1;
}

.FormDateTime__Input {
	appearance: none;
	background-color: white;
	color: var(--color-secondary-2);
	display: flex;
	align-items: center;
	padding-left: 3.5rem;

	&:focus-within,
	&:valid {
		transition: all 100ms ease-in-out;
		border: 1px solid var(--color-primary-5); // TODO doesn't work
	}

	&:hover {
		color: var(--color-primary-6);
	}

	&[type='date'] {
		&::before {
			content: '';
			position: absolute;
			left: 1rem;
			width: 35px;
			height: 35px;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjk0NzQgMTEuMDUyNlY5LjIxMDUxSDIyLjEwNTNWMTEuMDUyNkgxMi44OTQ3VjkuMjEwNTFIMTEuMDUyNlYxMS4wNTI2SDcuMzY4NDFWMjUuNzg5NUgyNy42MzE2VjExLjA1MjZIMjMuOTQ3NFpNMjUuNzg5NSAyMy45NDc0SDkuMjEwNTFWMTIuODk0N0gxMS4wNTI2VjE0LjczNjhIMTIuODk0N1YxMi44OTQ3SDIyLjEwNTNWMTQuNzM2OEgyMy45NDc0VjEyLjg5NDdIMjUuNzg5NVYyMy45NDc0WiIgZmlsbD0iIzBGMTk1QSIvPgo8cGF0aCBkPSJNMjMuOTQ3MyAxNi41Nzg5SDIyLjEwNTJWMTguNDIxSDIzLjk0NzNWMTYuNTc4OVoiIGZpbGw9IiMwRjE5NUEiLz4KPHBhdGggZD0iTTIwLjI2MzEgMTYuNTc4OUgxOC40MjFWMTguNDIxSDIwLjI2MzFWMTYuNTc4OVoiIGZpbGw9IiMwRjE5NUEiLz4KPHBhdGggZD0iTTE2LjU3ODkgMTYuNTc4OUgxNC43MzY4VjE4LjQyMUgxNi41Nzg5VjE2LjU3ODlaIiBmaWxsPSIjMEYxOTVBIi8+CjxwYXRoIGQ9Ik0xMi44OTQ3IDE2LjU3ODlIMTEuMDUyNlYxOC40MjFIMTIuODk0N1YxNi41Nzg5WiIgZmlsbD0iIzBGMTk1QSIvPgo8cGF0aCBkPSJNMjMuOTQ3MyAyMC4yNjMxSDIyLjEwNTJWMjIuMTA1MkgyMy45NDczVjIwLjI2MzFaIiBmaWxsPSIjMEYxOTVBIi8+CjxwYXRoIGQ9Ik0yMC4yNjMxIDIwLjI2MzFIMTguNDIxVjIyLjEwNTJIMjAuMjYzMVYyMC4yNjMxWiIgZmlsbD0iIzBGMTk1QSIvPgo8cGF0aCBkPSJNMTYuNTc4OSAyMC4yNjMxSDE0LjczNjhWMjIuMTA1MkgxNi41Nzg5VjIwLjI2MzFaIiBmaWxsPSIjMEYxOTVBIi8+CjxwYXRoIGQ9Ik0xMi44OTQ3IDIwLjI2MzFIMTEuMDUyNlYyMi4xMDUySDEyLjg5NDdWMjAuMjYzMVoiIGZpbGw9IiMwRjE5NUEiLz4KPC9zdmc+Cg==');
		}

		&::-webkit-calendar-picker-indicator {
			position: absolute;
			left: 1rem;
			width: 100%;
			height: 24px;
			opacity: 0;
		}
	}
}

// Validation required - styling of star
.ValidationRequired {
	.Form__Element__Caption:after {
		content: '' !important;
	}
}

.EPiServerForms .ValidationFail input,
.EPiServerForms .ValidationFail select {
	border: 1px solid var(--color-supportive-1) !important;
}

.Form__Element__ValidationError {
	margin-top: 0.5rem;
	color: var(--color-supportive-1) !important;
}

// Remove dashed border from default episerver styles and add focus visible styles for assessibility
.EPiServerForms .FormSubmitButton:focus,
.EPiServerForms .FormResetButton:focus {
	outline: none !important;
}

.EPiServerForms .FormSubmitButton:focus-visible,
.EPiServerForms .FormResetButton:focus-visible {
	outline: -webkit-focus-ring-color auto 1px !important;
}

.EPiServerForms .FormChoice .FormChoice__Input:focus-visible + .FormChoice__Label {
	outline: -webkit-focus-ring-color auto 1px;
}

.EPiServerForms .FormChoice .FormChoice__Input--Radio:focus + .FormChoice__Label {
	border: none !important;
}

.EPiServerForms .FormChoice .FormChoice__Input--TagBox:focus + .FormChoice__Label {
	border: 1px solid var(--color-primary-1) !important;
}

.FormChoice {
	// Container for radio/checkbox
	fieldset {
		border: 0;
		padding: 0;

		// Radio
		.radio-container,
		.checkbox-container {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;
		}

		&:has(.FormChoice__Input--Radio) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.radio-container {
			margin-right: 2rem;
		}
	}

	// Check & radio
	input[type='checkbox'],
	input[type='radio'] {
		// Hide default browser radio btn & check box
		-webkit-appearance: none;
		appearance: none;
		background-color: white;
		margin: 0;

		// New custom style for radio btn & check box
		border: solid 1px var(--color-primary-1);
		width: 24px;
		height: 24px;
		border-radius: 0;
		margin-right: 0.5rem;

		// Centering checked
		display: grid;
		place-content: center;

		// checked style (not visible here)
		&::before {
			background-color: var(--color-primary-1);
			transform: scale(0);
			box-shadow: inset 1em 1em var(--color-primary-1);
		}

		&:checked::before {
			// Make checked visible
			transform: scale(1);
		}

		&:hover {
			cursor: pointer;
		}
	}

	// Checkbox
	input[type='checkbox'] {
		// New custom style for check box

		// checkbox checked style (not visible here)
		&::before {
			content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC42MTA1IDAuMTkxNTg5TDQuNzQzMTQgNi4wNTg5NkwxLjM5NTc4IDIuNzE3OUwwLjUwNTI0OSAzLjYwODQzTDQuNzQzMTQgNy44NDYzMkwxMS41MDEgMS4wODIxMkwxMC42MTA1IDAuMTkxNTg5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
			display: flex;
			justify-content: center;
			align-items: center;
			width: 24px;
			height: 24px;
		}

		&:hover {
			background-color: var(--color-primary-1-25);
		}
	}

	// Radio
	input[type='radio'] {
		// New custom style for radio button
		border-radius: 50%;

		// Radio checked style (not visible here)
		&::before {
			content: '';
			width: 14px;
			height: 14px;
			border-radius: 50%;
		}
	}

	&__Label {
		font-size: 18px;
	}
}

.FormSubmitButton {
	// Submit button
	display: flex !important;
}

.EPiServerForms .Form__Status .Form__Success__Message {
	background-color: transparent !important;
	color: var(--color-primary-5) !important;
	padding: 0 !important;

	p:last-child {
		margin-bottom: 0 !important;
	}
}

// Remove recaptcha
.grecaptcha-badge {
	visibility: hidden !important;
}

// Style recaptcha disclaimer text
.formcontainerblock__recaptcha {
	font-size: 14px !important;
	color: var(--color-secondary-3);
	text-align: left;
	margin-top: 1rem;
	a {
		color: var(--color-primary-1);
	}
}

// Dropdown/selection
.FormSelection {
	select {
		appearance: none;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNyAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNDI1OCAxMC41MDkyTDAuMTI2NTg3IDIuMjIyMzhMMS44ODI5IDAuNDY2MDY0TDguNDI1OCA3LjAyMTMzTDE0Ljk2ODcgMC40NjYwNjRMMTYuNzI1IDIuMjIyMzhMOC40MjU4IDEwLjUwOTJaIiBmaWxsPSIjMEYxOTVBIi8+Cjwvc3ZnPgo=');
		background-position: 96% 50%;
		background-size: 16px 10px;
		color: var(--color-secondary-2);

		@include mq('sm') {
			background-position: 98%;
		}

		&:hover {
			cursor: pointer;
			color: var(--color-primary-6);
		}

		&:valid,
		&:focus {
			color: var(--color-primary-6);
			border: 1px solid var(--color-primary-5);
		}

		option {
			color: var(--color-primary-6);
		}
	}
}
