@import '@almbrand/styles/dist/media-mixins.scss';

.paragraph {
	border-radius: var(--corner-size--md);
}

.paragraph__add-fade {
	position: relative;

	&::before {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		content: '';
		height: 5em;
		background: linear-gradient(180deg, #fff0 0, var(--blockBgColor));
		z-index: 1;
	}
}

.paragraph__contentVisible--triggerWrapper {
	padding-top: var(--spacing--lg);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--spacing--lg);

	button {
		background-color: transparent;
		border: none;
		padding: 0;
	}
}

hr {
	flex-grow: 1;
	border-top: 1px solid var(--color-secondary-3);
}

.paragraph__contentVisible--triggerIcon {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	padding: 0;
	border-radius: 50%;
	background-color: var(--color-secondary-4);
	transition:
		background-color 300ms ease-in-out,
		transform 300ms ease-in-out;

	&:hover {
		background-color: var(--color-primary-1);

		span {
			background-color: var(--color-primary-4);
		}
	}

	&:active {
		background-color: var(--color-primary-2);
	}

	@include mq('lg') {
		width: 64px;
		height: 64px;
	}

	span {
		width: 24px;
		height: 1px;
		background-color: var(--color-primary-5);
		transition:
			background-color 300ms ease-in-out,
			transform 0.3s ease,
			opacity 0.3s ease;

		&:first-child {
			transform: rotate(0deg);
		}

		&:last-child {
			transform: rotate(90deg);
			position: absolute;
		}
	}
}

.paragraph__contentVisible--active {
	.paragraph__contentVisible--triggerIcon {
		&:first-child {
			transform: rotate(-45deg);
		}
		&:last-child {
			transform: rotate(45deg);
		}
	}

	hr {
		border-top: 1px solid var(--color-primary-5);
	}
}

.paragraph__contentText {
	overflow: hidden;
	max-height: 0;
	transition:
		max-height 0.6s ease,
		padding 0.6s ease;
}
