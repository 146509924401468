// Mixin for text alignment
@mixin textAlignment {
	// Text alignment values
	$alignments: (
		left: left,
		center: center,
		right: right,
	) !default;

	// Loop generating all text alignment styles
	@each $align-short, $align-type in $alignments {
		.text-#{$align-short} {
			text-align: $align-type !important;
		}
	}
}
// Include the text alignment mixin
@include textAlignment;
