#chat-container .bai-container-5799230b {
	border-radius: 16px;
}

#chat-container .bai-title-aee80e01 {
	font-weight: 400;
}

#chat-container .bai-linkButton-c30ef4f5 {
	border-radius: 8px;
}
